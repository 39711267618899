import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

const menuLinks = [
  'Pagrindinis', 'Paslaugos', 'Mūsų veiklos istorijos', 'Edukacija', 'Savanorystė',
  // 'Pagrindinis', 'Paslaugos', 'Mūsų veiklos istorijos', 'Edukacija', 'Savanorystė', 'Galerija'
]

const Menu = ({ onToggleMenu }) => (
  <nav id="menu">
    <div className="inner">
      <ul className="links">
        {menuLinks.map((link, index) => {
          const linkTo = index === 0
            ? '/'
            : link === 'Edukacija'
              ? '/paslaugos'
              : link === 'Savanorystė'
                ? '/savanoryste'
                : link === 'Mūsų veiklos istorijos'
                  ? '/musu-veiklos-istorijos'
                  : `/${link.toLowerCase()}`

          return (
            <li key={link}>
              <Link onClick={onToggleMenu} to={linkTo}>{link}</Link>
            </li>
          )
        }) }
      </ul>
      {/*<ul className="actions vertical">*/}
      {/*    <li><a href="#" className="button special fit">Get Started</a></li>*/}
      {/*    <li><a href="#" className="button fit">Log In</a></li>*/}
      {/*</ul>*/}
    </div>
    <div className="close" onClick={onToggleMenu} />
  </nav>
)

Menu.propTypes = {
    onToggleMenu: PropTypes.func
}

export default Menu
