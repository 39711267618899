import React from 'react'

const Contact = (props) => (
    <section id="contact">
        {/*<div className="inner">*/}
            <section className="split">
                <section>
                    <div className="contact-method">
                        <span className="icon alt fa-envelope"></span>
                        <h4>E-paštas</h4>
                        <a href="#">info@krastopazinimas.lt</a>
                    </div>
                </section>
                <section>
                    <div className="contact-method">
                        <span className="icon alt fa-phone"></span>
                        <h4>Telefonas</h4>
                        <span>+370 698 76957</span>
                    </div>
                </section>
                {/*<section>*/}
                {/*    <div className="contact-method">*/}
                {/*        <span className="icon alt fa-home"></span>*/}
                {/*        <h3>Address</h3>*/}
                {/*        <span>1234 Somewhere Road #5432<br />*/}
                {/*        Nashville, TN 00000<br />*/}
                {/*        United States of America</span>*/}
                {/*    </div>*/}
                {/*</section>*/}
            </section>
        {/*</div>*/}
    </section>
)

export default Contact
